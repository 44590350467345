<template>
  <v-card flat>
    <v-card-title>
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        dense
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-autocomplete
        style="max-width: 200px;"
        v-model="selectedCliente"
        :items="clientes"
        :loading="loadingCliente"
        :search-input.sync="searchCliente"
        clearable
        @input="
          buscaCliente(selectedCliente, dataCalendario + '-01', selectedStatus)
        "
        dense
        solo
        flat
        hide-details
        hide-no-data
        outlined
        class="mx-6 my-0"
        item-text="nome_fantasia"
        item-value="id"
        label="Clientes"
        prepend-inner-icon="mdi-account-group"
        return-object
      >
      </v-autocomplete>
      <v-card
        outlined
        class="d-flex mr-6 align-center"
        height="40px"
        style="overflow: hidden"
      >
        <v-btn
          @click="subMonth"
          color="white"
          tile
          depressed
          height="40px"
          class="px-1"
          min-width="40px"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-divider vertical class="ma-0"></v-divider>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="true"
          :return-value.sync="dataCalendario"
          transition="slide-x-reverse-transition"
          slide-y-transition
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text>
              {{ mostraMes }}
              <v-icon right>mdi-calendar</v-icon>
            </v-btn>
          </template>
          <v-date-picker
            v-model="dataCalendario"
            type="month"
            color="blue"
            no-title
            scrollable
            @change="$refs.menu.save(dataCalendario)"
          >
            <!-- <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(search)">
              OK
            </v-btn> -->
          </v-date-picker>
        </v-menu>
        <v-divider vertical class="ma-0"></v-divider>
        <v-btn
          @click="addMonth"
          color="white"
          tile
          depressed
          height="40px"
          class="px-1"
          min-width="40px"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card>
      <v-select
        v-model="selectedStatus"
        :items="status"
        style="max-width: 200px;"
        label="Status"
        item-text="tipo"
        item-value="id"
        hide-details
        outlined
        return-object
        dense
        class="mr-5"
      >
      </v-select>
      <v-btn
        @click="createMidiaCalendario"
        align="center"
        color="secondary"
        class="white--text"
      >
        adicionar
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="midiaCalendarios"
        :items-per-page="10"
        :sort-desc="true"
        class="data-tables data-tables__row-click"
        @click:row="goToListaPostagemCalendario"
      >
        <template v-slot:item.status="{ item }">
          {{ item.status | statusCalendario }}
        </template>
        <template v-slot:item.inicio="{ item }">
          {{ item.inicio | dateFormat("dd/MM/yyyy") }}
        </template>
        <template v-slot:item.fim="{ item }">
          {{ item.fim | dateFormat("dd/MM/yyyy") }}
        </template>
      </v-data-table>
    </v-card-text>
    <AddCalendarioMidiaSociais
      v-if="dialogMidiaCalendario"
      :dialogMidiaCalendario.sync="dialogMidiaCalendario"
      @fetch-calendarios="getMidiaCalendarios"
    />
  </v-card>
</template>

<script>
import { fetchMidiasSociaisCalendarios } from "@/api/gestor/midias_sociais_calendario.js";
import { fetchClientes } from "@/api/gestor/clientes.js";
import {
  // intervalToDuration,
  parseISO,
  format,
  addMonths,
  subMonths,
  // startOfMonth,
  // lastDayOfMonth,
} from "date-fns";
export default {
  name: "ListaCalendariosMidiaSociais",

  components: {
    AddCalendarioMidiaSociais: () => import("./AddCalendarioMidiaSociais.vue"),
  },

  data() {
    return {
      search: "",
      selectedStatus: null,
      searchCliente: "",
      midiaCalendarios: [],
      loadingCliente: false,
      selectedCliente: null,
      midiaCalendario: {},
      loading: true,
      status: [
        { id: null, tipo: "TODOS" },
        { id: 0, tipo: "CANCELADO" },
        { id: 1, tipo: "ATIVO" },
        { id: 2, tipo: "CONCLUIDO COLABORADOR" },
        { id: 3, tipo: "CONCLUIDO GESTOR" },
        { id: 4, tipo: "CONCLUIDO CLIENTE" },
      ],
      dialogMidiaCalendario: false,
      dialogListaPostagem: false,
      dataCalendario: format(new Date(), "yyyy-MM"),
      add: false,
      clientes: [],
      menu: false,
    };
  },
  watch: {
    dataCalendario() {
      if (this.dataCalendario) {
        this.buscaCliente(
          this.selectedCliente,
          this.dataCalendario + "-01",
          this.selectedStatus
        );
      }
    },
    selectedStatus() {
      if (this.selectedStatus) {
        this.buscaCliente(
          this.selectedCliente,
          this.dataCalendario + "-01",
          this.selectedStatus
        );
      }
    },
  },

  computed: {
    mostraMes() {
      let date = this.dataCalendario + "01";
      return format(parseISO(date), "MMM");
    },
    headers() {
      return [
        {
          text: "Cliente",
          value: "cliente.nome_fantasia",
        },
        {
          text: "Data inicio",
          value: "inicio",
        },
        {
          text: "Data final",
          value: "fim",
        },
        {
          text: "Status",
          value: "status",
        },
      ];
    },
  },

  methods: {
    buscaCliente(item1, item2, item3) {
      if (item1 && item2 && item3) {
        if (item3.id === null) {
          this.listaAtividades = [];
          this.getMidiaCalendarios(`?cliente_id=${item1.id}&data=${item2}`);
        } else {
          this.listaAtividades = [];
          this.getMidiaCalendarios(
            `?cliente_id=${item1.id}&data=${item2}&status=${item3.id}`
          );
        }
      } else if (item1 && item2) {
        this.listaAtividades = [];
        this.getMidiaCalendarios(`?cliente_id=${item1.id}&data=${item2}`);
      } else if (item2 && item3) {
        if (item3.id === null) {
          this.listaAtividades = [];
          this.getMidiaCalendarios(`?data=${item2}`);
        } else {
          this.listaAtividades = [];
          this.getMidiaCalendarios(`?data=${item2}&status=${item3.id}`);
        }
      } else if (item1) {
        this.listaAtividades = [];
        this.getMidiaCalendarios(`?cliente_id=${item1.id}`);
      } else if (item2) {
        this.listaAtividades = [];
        this.getMidiaCalendarios(`?data=${item2}`);
      }
    },
    addMonth() {
      this.dataCalendario = format(
        addMonths(parseISO(this.dataCalendario), 1),
        "yyyy-MM"
      );
    },
    subMonth() {
      this.dataCalendario = format(
        subMonths(parseISO(this.dataCalendario), 1),
        "yyyy-MM"
      );
    },
    getClientes() {
      this.loadingCliente = true;
      return fetchClientes()
        .then((response) => {
          this.clientes = response;
          this.loadingCliente = false;
        })
        .catch(() => {
          this.loadingCliente = false;
        });
    },
    createMidiaCalendario() {
      this.midiaCalendario = {};
      this.dialogMidiaCalendario = true;
    },

    goToListaPostagemCalendario(item) {
      this.$router.push({
        path: `/gestor/formulario-trabalho/midias-sociais/calendario-midias-sociais/${item.id}`,
      });
    },

    getMidiaCalendarios(filtro) {
      this.loading = true;
      return fetchMidiasSociaisCalendarios(filtro ? filtro : "")
        .then((response) => {
          this.midiaCalendarios = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getClientes();
    await this.getMidiaCalendarios();
  },
};
</script>

<style lang="scss"></style>
